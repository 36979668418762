<template>
  <div class="container">
    <div class="row justify-content-center mt-3 mb-5">
      <div class="mb-5">
        <PageTitle :title="'Coaching survey'"></PageTitle>

        <ErrorMessage v-if="error" :error="error"></ErrorMessage>

        <form
          @submit.prevent="send"
          v-else
          :class="isSubmitting ? 'is-busy' : ''"
          class="my-4"
        >
          <div class="fst-italic" v-html="description"></div>

          <div class="form-text fst-italic text-danger mt-2">All fields are mandatory.</div>

          <div
            v-for="(group, groupIndex) in survey.groups"
            :key="`key-g-${groupIndex}`"
            class="my-3"
          >
            <PageSubtitle :subtitle="group.name" class="border-top my-4 pt-3"></PageSubtitle>
            <div
              v-for="(question, questionIndex) in group.questions"
              :key="`key-q-${questionIndex}`"
            >
              <div v-if="question.type === Boolean" class="my-3">
                <label class="form-label">{{ question.label }}</label>
                <div class="form-check">
                  <input
                    v-model="question.value"
                    :id="`q-${groupIndex}-${questionIndex}-1`"
                    :name="`q-${groupIndex}-${questionIndex}-1`"
                    class="form-check-input"
                    type="radio"
                    value="Yes"
                  >
                    <label
                      :for="`q-${groupIndex}-${questionIndex}-1`"
                      class="form-check-label"
                    >Yes</label>
                </div>
                <div class="form-check">
                  <input
                    v-model="question.value"
                    :id="`q-${groupIndex}-${questionIndex}-0`"
                    :name="`q-${groupIndex}-${questionIndex}-0`"
                    class="form-check-input"
                    type="radio"
                    value="No"
                  >
                    <label
                      :for="`q-${groupIndex}-${questionIndex}-0`"
                      class="form-check-label"
                    >No</label>
                </div>
              </div>

              <div v-else-if="question.type === String" class="my-3">
                <label
                  :for="`q-${groupIndex}-${questionIndex}`"
                  :class="question.optional === true ? 'smaller fst-italic text-muted' : ''"
                  class="form-label"
                >{{ question.label }}</label>
                <textarea
                  v-model.trim="question.value"
                  :id="`q-${groupIndex}-${questionIndex}`"
                  class="form-control"
                  placeholder="Answer..."
                ></textarea>
              </div>

              <div v-else-if="question.type === Array" class="my-3">
                <label class="form-label">{{ question.label }}</label>
                <div
                  v-for="(option, optionIndex) in question.options"
                  :key="`key-${groupIndex}-${questionIndex}-${optionIndex}`"
                  class="form-check"
                >
                  <input
                    v-model="question.value"
                    :id="`key-${groupIndex}-${questionIndex}-${optionIndex}`"
                    :name="`key-${groupIndex}-${questionIndex}-${optionIndex}`"
                    :type="question.multiple ? 'checkbox' : 'radio'"
                    :value="option"
                    class="form-check-input"
                  >
                    <label
                      :for="`key-${groupIndex}-${questionIndex}-${optionIndex}`"
                      class="form-check-label"
                    >{{ option }}</label>
                </div>
              </div>
            </div>
          </div>

          <div class="border-top my-4 pt-3">
            <div v-if="isSuccess" class="alert alert-success">
              Thanks for completing the questionnaire.
            </div>
            <SubmitButton
              :text="'Submit my answers'"
              :textBusy="'Submitting...'"
              :isLoading="isSubmitting"
              :disabled="isSubmitting || isSubmitDisabled"
              class="btn btn-primary"
            ></SubmitButton>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { httpPost } from '@/core/http';

const initialSurvey = () => ({
  groups: [
    {
      name: 'Physical activity pre-screening health questionnaire',
      questions: [
        {
          label: 'Has your medical practitioner ever told you that you have a heart condition?',
          type: Boolean,
          value: '',
        },
        {
          label: 'Do you have any medical conditions that may be made worse by participating in physical activity?',
          type: Boolean,
          value: '',
        },
        {
          label: 'Do you experience any major muscle or joint conditions that may limit you or be aggravated by physical activity?',
          type: Boolean,
          value: '',
        },
        {
          label: 'Do you consistently feel faint or suffer from spells of dizziness?',
          type: Boolean,
          value: '',
        },
        {
          label: 'If you have answered YES to any of the questions above, please provide more information here:',
          type: String,
          value: '',
          optional: true,
        },

        {
          label: 'Are you asthmatic or require medication?',
          type: Boolean,
          value: '',
        },
        {
          label: 'Are you type I or type II diabetic?',
          type: Boolean,
          value: '',
        },
        {
          label: 'Is your blood pressure high above 140/90 or low below 100/80?',
          type: Boolean,
          value: '',
        },
        {
          label: 'Do you suffer from high cholesterol or low blood sugar levels?',
          type: Boolean,
          value: '',
        },
        {
          label: 'Are you currently on any medication?',
          type: Boolean,
          value: '',
        },
        {
          label: 'If you have answered YES to any of the questions above, please provide more information here:',
          type: String,
          value: '',
          optional: true,
        },

        {
          label: 'Have you ever had a stroke?',
          type: Boolean,
          value: '',
        },
        {
          label: 'Do you have a family history of heart disease, such as heart attack or stroke?',
          type: Boolean,
          value: '',
        },
        {
          label: 'Are you trying/pregnant or have given birth in past 12 months?',
          type: Boolean,
          value: '',
        },
        {
          label: 'Do you smoke?',
          type: Boolean,
          value: '',
        },
        {
          label: 'If you have answered YES to any of the questions above, please provide more information here:',
          type: String,
          value: '',
          optional: true,
        },
      ],
    },
    {
      name: 'Goal setting',
      questions: [
        {
          label: 'Are you currently exercising or participating in any training programs? If yes, how many times per week?',
          type: String,
          value: '',
        },
        {
          label: 'Does your occupation involve sedentary work or manual labour? Please elaborate.',
          type: String,
          value: '',
        },
        {
          label: 'How would you describe your current training experience?',
          type: Array,
          multiple: false,
          options: [
            'I am just starting out and I am looking for guidance.',
            'I have some experience and some familiarity with training terminology.',
            'I am quite experienced and quite familiar with training terminology.',
            'I am very experienced and very familiar with training terminology.',
          ],
          value: [],
        },
        {
          label: 'What goals are you looking to achieve with this exercise program?',
          type: Array,
          multiple: true,
          options: [
            'Weight loss/gain',
            'Build muscle',
            'Improve strength',
            'Improve stamina',
            'Improve flexibility',
            'Improve performance in sport',
            'Overcome an injury',
            'Mental wellbeing/stress release',
            'Nutrition/diet',
            'Medical',
            'Other',
          ],
          value: [],
        },
        {
          label: 'What are your top three fitness/nutrition goals?',
          type: String,
          value: '',
        },
        {
          label: 'On a scale of 1 to 10, how important are these goals to you?',
          type: String,
          value: '',
        },
        {
          label: 'Why are these goals most important to you?',
          type: String,
          value: '',
        },
        {
          label: 'In what timeframe do you expect to achieve your goals? Do you have a specific event/date you want to achieve these results by?',
          type: String,
          value: '',
        },
        {
          label: 'How much time per day/week are you able to commit to training?',
          type: String,
          value: '',
        },
        {
          label: 'Do you enjoy exercise more when it involves a routine that you can adhere to or one that offers variety?',
          type: String,
          value: '',
        },
        {
          label: 'Are you limited by any barriers? (e.g., long work hours, lack of facilities, injuries or disabilities)',
          type: String,
          value: '',
        },
      ],
    },
  ],
});

export default {
  name: 'CoachingSurvey',
  components: {
    ErrorMessage: () => import('@/components/message/ErrorMessage'),
    PageTitle: () => import('@/components/PageTitle'),
    PageSubtitle: () => import('@/components/PageSubtitle'),
    SubmitButton: () => import('@/components/button/SubmitButton'),
  },
  computed: {
    ...mapState('auth', ['authUser']),
    coachId() {
      return this.$route.params.coachId;
    },
    athleteId() {
      return this.$route.params.athleteId;
    },
    description() {
      return `
        This questionnaire is to help your coach to create a custom tailored program which suits you best.<br>
        The data submitted is only visible to your coach and is not shared elsewhere.<br>
      `;
    },
    isSubmitDisabled() {
      let isDisabled = false;
      this.survey.groups.forEach((group) => {
        group.questions.forEach((question) => {
          if (question.optional === true) return;
          if (!question.value.length) isDisabled = true;
        });
      });
      return isDisabled;
    },
  },
  methods: {
    async send() {
      this.isSubmitting = true;
      this.isSuccess = false;
      try {
        const { athleteId, coachId } = this;
        const data = this.getFormData();
        await httpPost(`/coaching/survey/${athleteId}/${coachId}`, { data });
        this.survey = initialSurvey();
        this.isSuccess = true;
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isSubmitting = false;
      }
    },
    getFormData() {
      const items = [];
      this.survey.groups.forEach((group) => {
        group.questions.forEach((question) => {
          items.push(`<p>${question.label}<br>${this.getQuestionData(question)}</p>`);
        });
      });
      return items.join('');
    },
    getQuestionData(question) {
      let answers = '';
      const { value } = question;
      if (value instanceof Array) {
        let arr = '';
        value.forEach((v) => {
          arr += `<li><i>${v}</i></li>`;
        });
        answers = `<ul>${arr}</ul>`;
      } else {
        answers = `<i>${this.$options.filters.nl2br(value)}</i>`;
      }
      return answers;
    },
  },
  data() {
    return {
      isSubmitting: false,
      isSuccess: false,
      error: null,
      survey: initialSurvey(),
    };
  },
  mounted() {
    const { authUser, coachId, athleteId } = this;
    if (!authUser || (authUser.userId !== coachId && authUser.userId !== athleteId)) {
      this.error = new Error('Unauthorised access.');
    }
  },
};
</script>
